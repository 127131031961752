import React, { useCallback, useState } from "react";
import MainContainerComponent from "../../components/MainContainerComponent";
import TextFieldComponent from "../../components/TextFieldComponent";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RESET_PASSWORD_API } from "../../constants/api";
import { useParams } from "react-router-dom";
import { POST } from "../../functions/axiosSending";
import ModalComponent from "../../components/ModalComponent";
import {
  EMAIL_TEXTFIELD_TEST_ID,
  PASSWORD_TEXTFIELD_TEST_ID,
  BTN_SUBMIT_TEST_ID,
  PASSWORD_CONFIRMATION_TEXTFIELD_TEST_ID,
} from "../../constants/testIds";

const resetSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .required("Pole jest wymagane")
      .email("Wpisany tekst nie jest adresem email"),
    password: Yup.string().required("Pole jest wymagane"),
  });

const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const { slug } = useParams();
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [btnTitle, setBtnTitle] = useState("");
  const [desc, setDesc] = useState("");
  const formik = useFormik({
    validationSchema: resetSchema(),
    initialValues: {
      email: "",
      password: "",
      password_confirmation: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      setError(false);
      POST({
        url: RESET_PASSWORD_API,
        data: { token: slug, ...values },
      })
        .then(() => {
          setTitle("Witamy");
          setBtnTitle("Przejdź do aplikacji");
          setDesc(
            "Twoje hasło zostało zmienieone. Teraz możesz zalogować się do aplikaji."
          );
          setLoading(false);
          setOpen(true);
        })
        .catch(() => {
          setTitle("Upss, coś poszło nie tak.");
          setDesc("Twoje hasło nie zostało zmienieone. Spróbuj ponownie.");
          setBtnTitle("Zamknij");
          setOpen(true);
          setLoading(false);
          setError(true);
        });
    },
  });

  const handleClose = useCallback(() => {
    console.log("WORK");
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    window.location.href = "outletPark://";
  }, []);
  return (
    <MainContainerComponent title="Wprowadź nowe hasło" loading={loading}>
      <>
        <form onSubmit={formik.handleSubmit}>
          <TextFieldComponent
            placeholder="Email"
            autoComplete="email"
            name="email"
            type="email"
            testId={EMAIL_TEXTFIELD_TEST_ID}
            formikProps={formik}
          />
          <TextFieldComponent
            placeholder="Nowe hasło"
            autoComplete="new-password"
            name="password"
            type="password"
            testId={PASSWORD_TEXTFIELD_TEST_ID}
            formikProps={formik}
          />
          <TextFieldComponent
            placeholder="Potwierdź nowe hasło"
            autoComplete="new-password"
            name="password_confirmation"
            type="password"
            testId={PASSWORD_CONFIRMATION_TEXTFIELD_TEST_ID}
            formikProps={formik}
          />
          <button
            className="btn"
            type="submit"
            data-testid={BTN_SUBMIT_TEST_ID}
          >
            Zmień hasło
          </button>
        </form>
        <ModalComponent
          show={open}
          title={title}
          subtitle={desc}
          handleClick={error ? handleClose : handleClick}
          btnTitle={btnTitle}
          handleClose={handleClose}
        />
      </>
    </MainContainerComponent>
  );
};

export default React.memo(ResetPasswordPage);
