import React from "react"
import { MODAL_BTN_TEST_ID, MODAL_TEST_ID } from "../constants/testIds"
import CloseSvg from "../svgs/CloseSvg"

interface IModalComponent {
	title: string
	btnTitle: string
	show: boolean
	disabled?: boolean
	subtitle: string
	handleClick: Function
	handleClose: React.MouseEventHandler<SVGSVGElement> | undefined
	children?: JSX.Element
}

const ModalComponent = ({ disabled, children, show, btnTitle, title, subtitle, handleClose = () => {}, handleClick = () => { }, }: IModalComponent) => {
	return (
		<section className={`modal ${show ? "show-modal" : ""}`}>
			{show ?
				<div className="modal-dialog" data-testid={MODAL_TEST_ID}>
					<div className="close-block">
						<CloseSvg className="close-icon" onClick={handleClose} />
					</div>
					<h2 className="modal-title">{title}</h2>
					<p className="modal-desc" dangerouslySetInnerHTML={{ __html: subtitle }} />
					{children ? children : null}
					<button disabled={disabled} className="modal-btn btn" onClick={() => handleClick()} data-testid={MODAL_BTN_TEST_ID}>{btnTitle}</button>
				</div>
				: null}
		</section>
	);
};

export default React.memo(ModalComponent);