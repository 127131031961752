export const TEXT_FIELD_ERROR_TEST_ID = 'text-field-error';
export const CHECKBOX_TEST_ID = 'checkbox';
export const EMPTY_PAGE_TEST_ID = 'empty-page';
export const LOADER_TEST_ID = 'loader';
export const TITLE_TEST_ID = 'title';
export const SUBTITLE_TEST_ID = 'subtitle';
export const APP_BTN_TEST_ID = 'app-btn';
export const PIN_TEST_ID = 'pin';
export const BTN_SUBMIT_TEST_ID = 'btn-submit';
export const MODAL_TEST_ID = 'modal';
export const MODAL_BTN_TEST_ID = 'btn-modal';
export const EMAIL_TEXTFIELD_TEST_ID = 'email-textfield';
export const PASSWORD_TEXTFIELD_TEST_ID = 'password-textfield';
export const PASSWORD_CONFIRMATION_TEXTFIELD_TEST_ID = 'password-confirmation-textfield';