import axios from 'axios';
import { MAIN_API_URL } from '../constants/api';
interface IGet {
	url: string,
	params?: object
}
interface IPost {
	url: string,
	data?: object
}

export const GET = async ({ url, params = {}}: IGet) => {
	return axios
		.get(`${MAIN_API_URL}/api${url}`, {
			params: params
		})
};
export const POST = async ({ url, data = {}}: IPost) => {
	return axios
		.post(`${MAIN_API_URL}/api${url}`, data)
};