import React from "react"
import MainContainerComponent from "../../components/MainContainerComponent";
import { EMPTY_PAGE_TEST_ID } from "../../constants/testIds";

const EmptyPage = () => {
	return (
		<MainContainerComponent loading={false} title="404">
			<div data-testid={EMPTY_PAGE_TEST_ID}>
			</div>
		</MainContainerComponent>
	);
};

export default React.memo(EmptyPage);