import React from "react"
import { Route, Routes } from "react-router-dom";
import { CHECK_REWARDS_ROUTE, CONFIRM_REGISTER_ROUTE, EMPTY_ROUTE, RESET_PASSWORD_ROUTE } from "./constants/routes";
import CheckRewardsPage from "./pages/checkRewardsPage/CheckRewardsPage";
import ConfirmRegisterPage from "./pages/confirmRegisterPage/ConfirmRegisterPage";
import EmptyPage from "./pages/emptyPage/EmptyPage";
import ResetPasswordPage from "./pages/resetPasswordPage/ResetPasswordPage";

const Root = () => {

	return (
		<Routes>
			<Route path={`${RESET_PASSWORD_ROUTE}/:slug`} element={<ResetPasswordPage />} />
			<Route path={`${CHECK_REWARDS_ROUTE}/:slug`} element={<CheckRewardsPage />} />
			<Route path={EMPTY_ROUTE} element={<EmptyPage />} />
			<Route path={`${CONFIRM_REGISTER_ROUTE}/:id/:hash`} element={<ConfirmRegisterPage />} />
		</Routes>
	);
};

export default React.memo(Root);