import React, { useMemo } from "react"
import { TEXT_FIELD_ERROR_TEST_ID } from "../constants/testIds"

interface ITextFieldComponent {
	name: string
	testId?: string
	pattern?: string
	placeholder?: string
	autoComplete?: string
	type?: string
	formikProps: {
		values: any
		errors: any
		touched: any
		handleBlur: Function
		handleChange: Function
	}
	required?: boolean
}

const TextFieldComponent = ({ testId, formikProps, name, required, type, autoComplete, placeholder, pattern }: ITextFieldComponent) => {
	const value = useMemo(() => name && formikProps?.values ? formikProps.values[name] : "", [formikProps, name]);
	const error = useMemo(() => name && formikProps?.errors ? formikProps.errors[name] : "", [formikProps, name]);
	const touched = useMemo(() => name && formikProps?.touched ? formikProps.touched[name] : false, [formikProps, name]);
	const isError = useMemo(() => error && Boolean(touched) ? true : false, [error, touched]);

	const handleBlur = (event: any) => {
		formikProps.handleBlur(event)
	}
	const handleChange = (event: any) => {
		formikProps.handleChange(event)
	}
	return (
		<div className="input-cont">
			<input
				data-testid={testId}
				placeholder={placeholder}
				aria-invalid="true"
				className="input"
				autoComplete={autoComplete}
				pattern={pattern}
				id={name}
				name={name}
				value={value}
				type={type}
				required={required}
				onChange={(e) => handleChange(e)}
				onBlur={(e) => handleBlur(e)}
			/>
			{isError ? <p className="error" data-testid={TEXT_FIELD_ERROR_TEST_ID}>{error}</p> : null}
		</div>
	);
};

export default React.memo(TextFieldComponent);