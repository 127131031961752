import * as React from "react";

interface ICloseSvg {
  className?: string
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined
}
const CloseSvg = ({ className, onClick = () => {} }: ICloseSvg) => (
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
    }}
    className={className}
    onClick={onClick}
  >
    <path
      style={{
        fill: "#fff",
      }}
      d="M-19.089-16.972h44.26v41.634h-44.26z"
      transform="matrix(.97152 0 0 1.03282 20.645 19.629)"
    />
    <path
      d="M25.172-16.972h-44.261v41.634h44.261v-41.634Zm-1.098 1.033v39.568h-42.065v-39.568h42.065Z"
      style={{
        fill: "#1D1E1C",
      }}
      transform="matrix(.97152 0 0 1.03282 20.645 19.629)"
    />
    <path
      d="M1909.37 4823.41h6.63"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1D1E1C",
        strokeWidth: 2,
      }}
      transform="rotate(45 6761.558 143.312)"
    />
    <path
      d="M1909.37 4823.41h6.63"
      style={{
        fill: "none",
        fillRule: "nonzero",
        stroke: "#1D1E1C",
        strokeWidth: 2,
      }}
      transform="rotate(-45 -4825.32 4704.065)"
    />
  </svg>
);

export default CloseSvg;
