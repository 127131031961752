import React, { useCallback, useState } from "react"
import MainContainerComponent from "../../components/MainContainerComponent";
import TextFieldComponent from "../../components/TextFieldComponent";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { CHECK_USER_CARD_API, GIVE_USER_CARD_API } from "../../constants/api";
import { useParams } from "react-router-dom";
import { POST } from "../../functions/axiosSending";
import ModalComponent from "../../components/ModalComponent";
import { BTN_SUBMIT_TEST_ID, CHECKBOX_TEST_ID, PIN_TEST_ID } from "../../constants/testIds";

const resetSchema = () => Yup.object().shape({
	pin: Yup.string()
		.required("Pole jest wymagane"),
});

interface IReward {
	name: string,
	id: number,
	checked?: boolean,
}

const CheckRewardsPage = () => {
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(false);
	const [successGave, setSuccessGave] = useState(false);
	const [disableBtn, setDisableBtn] = useState(false);
	const [title, setTitle] = useState('');
	const [btnTitle, setBtnTitle] = useState('');
	const [desc, setDesc] = useState('');
	const [rewards, setRewards] = useState<IReward[]>([]);
	const { slug } = useParams();

	const formik = useFormik({
		validationSchema: resetSchema(),
		initialValues: {
			pin: '',
		},
		onSubmit: values => {
			setLoading(true)
			setError(false)
			POST({
				url: CHECK_USER_CARD_API, data: { user_id: slug, pin: values?.pin }
			})
				.then((success) => {
					const data = success?.data?.data?.rewards;
					setTitle("Wydaj nagrodę");
					setDesc('Użytkownik zeskanowany poprawnie, wydaj nagrody o nazwie:');
					setRewards(data.map((r: IReward) => ({
						...r,
						checked: false,
					})))
					setLoading(false)
					setOpen(true)
					setBtnTitle("NAGRODY WYDAŁEM")
				})
				.catch((err) => {
					const status = err?.response?.data?.data?.error_status;
					let titleErr = '';
					let descErr = '';
					if (status === 0) {
						titleErr = "Skanowanie nieudane";
						descErr = "Użytkownik z taką kartą nie został odnaleziony.";
					} else if (status === 1) {
						titleErr = "Skanowanie przebiegło poprawnie";
						descErr = "Żadna nagroda nie została aktywowana przez użytkownika. Przekaż tę informację klientowi.";
					} else {
						titleErr = "Nieudana autoryzacja";
						descErr = "Wprowadziłeś niepoprawny pin. Spróbuj ponownie";
					}
					setTitle(titleErr);
					setDesc(descErr);
					setBtnTitle("Zamknij")
					setOpen(true)
					setLoading(false)
					setError(err)
				})
		},
	});

	const handleClose = useCallback(() => {
		setOpen(false)
		setSuccessGave(false)
		setLoading(false)
		setError(false)
		formik.resetForm();
	}, [formik])

	const handleGave = useCallback(() => {
		setLoading(true);
		setError(false);
		setOpen(false)
		POST({
			url: GIVE_USER_CARD_API,
			data: { user_id: slug, pin: formik?.values?.pin, give: rewards?.length ? rewards.filter((r) => r.checked).map((reward) => reward.id) : [] }
		})
			.then((success) => {
				setLoading(false)
				setSuccessGave(true)
				setOpen(true)
				setTitle("Udało się!");
				setDesc("Wszystko przebiegło prawidłowo. Nagroda trafiła do klienta ✅");
				setBtnTitle("Zamknij")
				formik.resetForm();
			})
			.catch((err) => {
				setError(true);
				setTitle("Nieudana autoryzacja");
				setDesc("Wprowadziłeś niepoprawny pin. Spróbuj ponownie");
				setBtnTitle("Zamknij")
			})

	}, [formik, slug, rewards])

	const handleChange = useCallback((key: number) => async () => {
		let toDisableBtn = true;
		await setRewards((oldRewards) => oldRewards.map((r, index) => {
			if ((index !== key && r.checked) || (index === key && !r.checked)) {
				toDisableBtn = false;
			}
			return index !== key ? r : ({
				...r,
				checked: !r.checked,
			})
		}))
		setDisableBtn(toDisableBtn);
	}, [])
	return (
		<MainContainerComponent
			title="Strefa pracownika"
			loading={loading}
			subtitle="Wprowadź indywidualny PIN i sprawdź jakie nagrody przysługują użytkownikowi aplikacji."
		>
			<>
				<form onSubmit={formik.handleSubmit}>
					<TextFieldComponent
						name='pin'
						type='password'
						testId={PIN_TEST_ID}
						placeholder="PIN"
						formikProps={formik}
					/>
					<button className="btn" type="submit" data-testid={BTN_SUBMIT_TEST_ID}>Wyślij</button>
				</form>
				<ModalComponent
					show={open}
					title={title}
					subtitle={desc}
					handleClick={error || successGave ? handleClose : handleGave}
					btnTitle={btnTitle}
					handleClose={handleClose}
					disabled={disableBtn}
				>
					<>
						{rewards?.length ? rewards.map((reward, key) =>
							<div key={key} className="checkbox-cont">
								<input type="checkbox" data-testid={CHECKBOX_TEST_ID} className="checkbox" name={`reward${key}`} onChange={handleChange(key)} checked={reward.checked} />
								<p>{reward.name}</p>
							</div>
						) : null}
					</>
				</ModalComponent>
			</>
		</MainContainerComponent>
	);
};

export default React.memo(CheckRewardsPage);
