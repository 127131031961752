import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainContainerComponent from "../../components/MainContainerComponent";
import { CONFIRM_REGISTER_API } from "../../constants/api";
import { EMPTY_ROUTE } from "../../constants/routes";
import { APP_BTN_TEST_ID } from "../../constants/testIds";
import { GET } from "../../functions/axiosSending";

const ConfirmRegisterPage = () => {
  const { id, hash } = useParams();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setOpen(true);
    if (id && hash) {
      setSuccess(false);
      GET({ url: `${CONFIRM_REGISTER_API}/${id}/${hash}` })
        .then(() => {
          setOpen(false);
          setSuccess(true);
        })
        .catch(() => {
          setOpen(false);
          navigate(EMPTY_ROUTE);
        });
    } else {
      setOpen(false);
      navigate(EMPTY_ROUTE);
    }
  }, [id, hash, navigate]);

  return (
    <MainContainerComponent
      title="Witamy"
      subtitle="Twoje konto zostało zweryfikowane. Teraz możesz zalogować się do aplikacji."
      loading={open}
    >
      <>
        {success ? (
          <a href="outletPark://" className="btn" data-testid={APP_BTN_TEST_ID}>
            PRZEJDŹ DO APLIKACJI
          </a>
        ) : null}
      </>
    </MainContainerComponent>
  );
};

export default React.memo(ConfirmRegisterPage);
