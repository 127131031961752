import React from "react"
import { LOADER_TEST_ID } from "../constants/testIds";
import LogoPng from '../imgs/logo.png';
interface ILoaderComponent {
	loading?: boolean
}


const LoaderComponent = ({ loading }: ILoaderComponent) => {

	return (
		<>
			{loading ? <section className="loader-section" data-testid={LOADER_TEST_ID}>
				<img src={LogoPng} alt="Logo Galaxy" className="logo-loader" />
			</section>
				: null}

		</>
	);
};

export default React.memo(LoaderComponent);